import { ENUM_BANK_ACCOUNT_TYPE, INPUT } from 'constants/enums';
import enGB from 'date-fns/locale/en-GB';
import pl from 'date-fns/locale/pl';
import * as R from 'ramda';
import { getStorageLanguage } from 'utils/hooks/storage/storageGets';
import translatePaths from 'utils/translatePaths';

export function translate(path) {
	const language = getStorageLanguage();
	return translatePaths[language][path];
}

export function hasValidEmail(email) {
	const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(String(email).toLowerCase());
}

export function hasValidPolandNip(nip) {
	if(!nip || typeof nip !== 'string') return false;

	const nipWithoutDashes = cutEmptySpaces(nip.replace(/-/g, ''));
	const regex = /^[0-9]{10}$/;

	if(regex.test(nipWithoutDashes) === false) {
		return false;
	} else {
		const digits = ('' + nipWithoutDashes).split('');
		const checkSum = (6*parseInt(digits[0]) + 5*parseInt(digits[1]) + 7*parseInt(digits[2]) + 2*parseInt(digits[3]) + 3*parseInt(digits[4]) + 4*parseInt(digits[5]) + 5*parseInt(digits[6]) + 6*parseInt(digits[7]) + 7*parseInt(digits[8]))%11;

		return (parseInt(digits[9]) === checkSum);
	}
}

export function hasValidPolandPhoneNumber(number) {
	if(!number) return false;

	const formattedNumber = cutEmptySpaces(number.replace(/-/g, ''));
	return formattedNumber?.length === 9;
}

export function hasValidCardNumber(number) {
	const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
	return regex.test(String(number).replace(/ /g, ''));
}

export function hasValidExpiryDate(date) {
	const regex = /^([0-1][0-9]\/[2-9][0-9])/;
	return regex.test(String(date));
}

export function hasValidCvcNumber(number) {
	const regex = /^[0-9]{3,4}$/;
	return regex.test(String(number));
}

export function hasValidFieldNumber(number) {
	const regex = /^\d+/;
	return regex.test(String(number));
}

const getFormattedValue = (value, ratio, options) => {
	if(value < 0) {
		value = R.negate(value);
	}

	value = (value / ratio);
	return value.toLocaleString('de-DE', options);
};

export function formatValueToEurope(value, currency = '', options) {
	const plus = options?.plus ?? '';
	const minus = options?.minus ?? '-';
	const emptyValue = options?.emptyValue ?? defaultEmptyValue;
	const withoutCents = options?.withoutCents ?? false;
	const cutZeros = options?.cutZeros ?? false;
	const ratio = options?.ratio ?? 1;

	if (value === null || value === undefined || value === '' || isNaN(value)) return emptyValue;

	const sign = value < 0 ? minus : plus;

	const formattedOptions = {
		minimumFractionDigits: withoutCents ? 0 : 2, maximumFractionDigits: withoutCents ? 0 : 2
	};

	let formattedValue = getFormattedValue(value, ratio, formattedOptions).replace(/([.])/g, '\xA0');
	const currencyString = currency ? ` ${currency}` : '';

	const cents = formattedValue.substr(formattedValue.length - 2, formattedValue.length);
	if(cutZeros && cents === '00') {
		formattedValue = formattedValue.substr(0, formattedValue.length - 3);
	}
	return `${sign}${formattedValue}${currencyString}`;
}

export function formatValueToEuropeWithVat(value, vatValue, options = {}) {
	const emptyValue = options?.emptyValue ?? defaultEmptyValue;
	const separator = options?.separator;
	const currency = options?.currency;

	if(value === null || value === undefined || value === '' || vatValue === null || vatValue === undefined || vatValue === '') return emptyValue;
	return separator ? `${formatValueToEurope(value, currency?.code)} ${separator} ${formatValueToEurope(vatValue)} VAT` : `${formatValueToEurope(value, currency?.code)} (${formatValueToEurope(vatValue)} VAT)`;
}

function contextEmpty() {
	let isEmpty = false;

	for (let i = 0; i < arguments.length; i++) {
		if (typeof arguments[i] === 'string' && !arguments[i].toLocaleString().length) {
			isEmpty = true;
		} else if (typeof arguments[i] === 'object' && arguments[i] === null) {
			isEmpty = true;
		} else if (isArray(arguments[i]) && arguments[i].length === 0) {
			isEmpty = true;
		} else if (isObject(arguments[i]) && Object.entries(arguments[i]).length === 0) {
			isEmpty = true;
		}
	}
	return isEmpty;
}

export function isEmpty(obj) {
	for(let prop in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if(obj.hasOwnProperty(prop))
			return false;
	}
	return true;
}

// there is at least one empty argument (string or null)
export function isAnyEmpty() {
	return contextEmpty.apply(this, arguments);
}

export function isObjectAnyEmptyValues(values) {
	for(const [, value] of Object.entries(values)) {
		if(isAnyEmpty(value)) {
			return true;
		}
	}
	return false;
}

export function isObjectAnyEmpty() {
	return contextEmpty.apply(this, arguments);
}

export function varToString(varObj) {
	return Object.keys(varObj)[0];
}

export function to(promise) {
	return promise
		.then(response => [null, response])
		.catch(error => [error]);
}

export function toAll(...args) {
	return Promise.all(args)
		.then(response => [null, response])
		.catch(error => [error]);
}

const isArray = item => {
	return Array.isArray(item);
};

export const isBoolean = value => {
	return typeof value === 'boolean';
};

const isObject = item => {
	return item === Object(item) && !isArray(item) && typeof item !== 'function';
};

const toCamel = string => {
	return string.replace(/([-_][a-z])/ig, ($1) => {
		return $1.toUpperCase()
			.replace('-', '')
			.replace('_', '');
	});
};

export const toSnake = string => string.replace(/([A-Z])/g, '_$1').toLowerCase();

export function keysTo(data, func = toCamel) {
	if (isObject(data)) {
		const object = {};

		Object.keys(data)
			.forEach((item) => {
				object[func(item)] = keysTo(data[item], func);
			});

		return object;
	} else if (isArray(data)) {
		return data.map((item) => {
			return keysTo(item, func);
		});
	}

	return data;
}

export const updateObject = (oldObject, properties) => ({ ...oldObject, ...properties });

export const scrollTo = (options) => {
	if(typeof window !== 'undefined') {
		window.scrollTo(options);
	}
};

export const setProperty = (name, value) => {
	if(typeof window !== 'undefined') {
		return document.documentElement.style.setProperty(name, value);
	}
};

export const getProperty = name => {
	if(typeof window !== 'undefined') {
		return cutEmptySpaces(window.getComputedStyle(document.documentElement).getPropertyValue(name));
	}
};

export const val = name => {
	if(typeof window !== 'undefined') {
		return window.getComputedStyle(document.documentElement).getPropertyValue(`--color-${name}`);
	}
};

export const cutEmptySpaces = text => text.replace(/\s/g, '');

export const cutCharacters = text => text.replace(/[^0-9]+/g, '');

export const countryToFlag = isoCode => {
	return (isoCode && typeof String.fromCodePoint !== 'undefined')
		? isoCode.toUpperCase().replace(/./g, (char) =>
			String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
};

export const dismissOnSubmitKey = event => {
	if(event.charCode === INPUT.KEY.SUBMIT) {
		event.currentTarget && event.currentTarget.blur();
		event.target && event.target.blur();
		event.preventDefault();
	}
};

export const convertToPln = (value, currencies, currencyCode) => {
	if(!value || !currencies || !currencyCode) return null;
	if(!currencies.length) return null;

	const currencyObj = currencies.find(item => item.code === currencyCode);
	return value * currencyObj.ratio;
};

export const replaceNthString = (string, nth = 3, replaceWith = ' ') => {
	const chars = string.split('');

	for (let i = nth-1; i < chars.length-1; i+=nth) {
		chars[i] = replaceWith;
	}

	return chars.join('');
};

// default - phone numbers
export const formatNthString = (string, nth = 3, joinWith = ' ') => {
	if(!string) return null;

	const result = [];

	while (string.length) {
		result.push(string.substr(0, nth));
		string = string.substr(nth);
	}

	return result.join(joinWith);
};

export const formatPhoneNumber = (item, nth = 3, emptyValue = defaultEmptyValue) => {
	const phonePrefix = item?.phoneCountryPrefix ?? '';
	const phoneNumber = item?.phoneNumber ?? '';
	return phoneNumber ? `${phonePrefix} ${formatNthString(phoneNumber, nth)}` : emptyValue;
};

export const formatCommonOptions = (options, id, opt) => {
	const idProp = opt?.idProp ?? 'id';
	const valueProp = opt?.valueProp ?? 'name';

	const option = options?.find(item => item[idProp] === id);
	return formatString(option ? option[valueProp] : null);
};

export const formatNotificationGroup = (options, includeItem) => {
	return formatStrings(options.filter(item => R.includes(item.id, includeItem)).map(x => x.name), ' ');
};

export const formatBankNumber = (string, type, options = {}) => {
	const emptyValue = options?.emptyValue ?? translate('empty.formatBankNumber');
	if(!string) return emptyValue;

	switch(type) {
		case ENUM_BANK_ACCOUNT_TYPE.NRB:
			const firstTwoChars = string.substr(0, 2);
			const numberToFormat = string.substr(2, string.length);
			return `${firstTwoChars} ${formatNthString(numberToFormat, 4, ' ')}`;
		case ENUM_BANK_ACCOUNT_TYPE.IBAN:
			return formatNthString(string, 4, ' ');
		default:
			return string;
	}
};

// TODO: deprecated, use FormatAddress component
export const formatAddress = (address, options, emptyValue) => {
	if(!address) {
		return emptyValue ?? translate('empty.formatAddress');
	}

	const { country, postalCode, city, street, buildingNumber, apartmentNumber } = address;
	if(!postalCode && !city && !country) return emptyValue;

	if(!street || !buildingNumber || !postalCode || !city) {
		if(postalCode && city) {
			return buildingNumber ? `${city} ${buildingNumber}, ${postalCode} ${city}` : `${postalCode} ${city}`;
		}
		return city ? city : country;
	}

	return apartmentNumber
		? `${translate('empty.street')} ${street} ${buildingNumber}/${apartmentNumber}, ${postalCode} ${city}`
		: `${translate('empty.street')} ${street} ${buildingNumber}, ${postalCode} ${city}`;
};

export const formatAddressCommune = (address, countries, emptyValue) => {
	if(!address) {
		return emptyValue ?? translate('empty.formatAddressCommune');
	}

	const { commune, district, province, country } = address;
	const tCommune = translate('empty.commune');
	const tDistrict1a = translate('empty.district.part.1a');
	const tDistrict1b = translate('empty.district.part.1b');
	const tDistrict2 = translate('empty.district.part.2');
	const tProvince = translate('empty.province');
	const tCountry = translate('empty.country');
	let output = '';

	if(commune) {
		output += `${tCommune} ${commune}`;
	}
	if(district) {
		if(output) output += tDistrict1a;
		if(!output) output += tDistrict1b;
		output += `${tDistrict2} ${district}`;
	}
	if(province) {
		if(output) output += ', ';
		output += `${tProvince} ${province}`;
	}
	if(country) {
		if(output) output += ', ';
		output += `${tCountry} ${formatString(countries?.find(item => item.code === country)).name}`;
	}
	return output;
};

export function convertToPx(rem) {
	if(typeof document === 'undefined') return rem * 16;
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function convertToRem(px) {
	if(typeof document === 'undefined') return px / 16;
	return px / parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function vw(number) {
	if(typeof window === 'undefined' || typeof document === 'undefined') return 320 * number / 100;

	const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	return vw * number / 100;
}

export const isChromiumBrowser = () => typeof window !== 'undefined' && window?.chrome;

export const isEdgeBrowser = () => {
	let isEdgeBrowser = false;

	if(typeof window !== 'undefined') {
		isEdgeBrowser = window.navigator.userAgent.indexOf('Edg') > -1;
		// TODO: user agent deprecated
		// const brands = window.navigator.userAgentData?.brands;
		// isEdgeBrowser = brands.find(item => item.brand.indexOf('Edg') > -1);
		// console.log('isEdgeBrowser', isEdgeBrowser);
	}
	return isEdgeBrowser;
};

export const isFirefoxBrowser = () => navigator.userAgent.indexOf('Firefox') !== -1;

const defaultEmptyValue = '-';

export const isValidAmount = (value) => value?.length > 3 && value[value.length - 3] === ',';

export function formatNip(value, emptyValue) {
	if (value === null || value === undefined || value === '') {
		return emptyValue ?? translate('empty.formatNip');
	}
	if(value?.length === 10) {
		return `${value[0]}${value[1]}${value[2]} ${value[3]}${value[4]}${value[5]} ${value[6]}${value[7]} ${value[8]}${value[9]}`;
	}
	return value;
}

export function formatBoolean(boolean, emptyValue = defaultEmptyValue) {
	if (boolean === null || boolean === undefined || boolean === '') return emptyValue;
	return boolean ? translate('boolean.yes') : translate('boolean.no');
}

export function formatString(value, emptyValue = defaultEmptyValue) {
	if (value === null || value === undefined || value === '') return emptyValue;
	return value;
}

export function splitTooLongString(value, maxWordLength = 28) {
	if(!value || typeof value !== 'string') return null;

	const output = value?.split(' ');
	const formattedError = output?.map(word => word?.length > maxWordLength ? formatNthString(word, maxWordLength) : word);
	return formattedError.join(' ');
}

export function formatStringMpp(value, isMpp, emptyValue = defaultEmptyValue) {
	if (value === null || value === undefined || value === '') return emptyValue;
	return isMpp ? `${value} (MPP)` : value;
}

export function formatPercentageValue(value, fractionDigits = 2, emptyValue = defaultEmptyValue) {
	if (value === null || value === undefined || value === '') return emptyValue;
	return `${parseFloat(value).toFixed(fractionDigits)} %`;
}

export function formatStrings(stringsArray, separator = '', emptyValue = defaultEmptyValue) {
	if(!stringsArray || !stringsArray?.length) return emptyValue;
	const hasEmptyValue = R.includes(null, stringsArray) || R.includes(undefined, stringsArray) || R.includes('', stringsArray) || R.includes('-', stringsArray);
	return hasEmptyValue ? emptyValue : stringsArray.join(separator);
}

export function combineReducers(...reducers) {
	return (state, action) => {
		for(const reducer of reducers) {
			const result = reducer(state, action);
			if(result) return result;
		}
	};
}

export function cleanRequest(values) {
	const obj = { ...values };
	for (let propName in obj) {
		if (obj[propName] === null || obj[propName] === undefined) {
			delete obj[propName];
		}
	}
	return obj;
}

// TODO: remove from app
export function getChangedValues(values, initialValues) {
	const cleanObject = cleanRequest(values);

	return Object.entries(cleanObject).reduce((acc, [key, value]) => {
		let hasChanged;

		// don't get new Date() values - problem with invalid date on DatePickerWrapper component
		if(typeof initialValues[key]?.getMonth === 'function' || typeof value?.getMonth === 'function') {
			hasChanged = false;
		// [] === []
		} else if(Array.isArray(initialValues[key]) || Array.isArray(value)) {
			hasChanged = JSON.stringify(initialValues[key]) !== JSON.stringify(value);
		} else {
			hasChanged = initialValues[key] !== value;
		}

		if (hasChanged) {
			acc[key] = value;
		}
		return acc;
	}, {});
}

export function updateNestedObject(params, response, path) {
	const values = params?.values;

	const nestedObject = { ...params.object };
	const pathProperties = path.split('.');
	const item = R.path(pathProperties, params.object);

	const data = response.data;

	// if(data?.slug) {
	// 	const currentIndex = item.findIndex(item => item.slug === data?.slug);
	// 	nestedObject[pathProperties][currentIndex] = data;
	// } else {
	// 	nestedObject[pathProperties] = data;
	// }

	if(response.config.method === 'post') {
		const indexItem = params?.indexItem;
		if(indexItem) {
			nestedObject[pathProperties][indexItem] = data;
		} else {
			nestedObject[pathProperties].push(data);
		}
	} else if(response.config.method === 'put' || response.config.method === 'patch') {
		const currentIndex = item.findIndex(item => item.slug === data?.slug);
		nestedObject[pathProperties][currentIndex] = data;
	} else if(response.config.method === 'delete') {
		if(data) {
			const currentIndex = item.findIndex(item => item.slug === values?.slug);
			nestedObject[pathProperties][currentIndex] = data;
		} else {
			const currentIndex = item.findIndex(item => item.slug === values?.slug);
			nestedObject[pathProperties][currentIndex] = null;
		}
	}
	return nestedObject;
}

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;
const ARGUMENT_NAMES = /([^\s,]+)/g;

export const isUndefined = prop => typeof prop === 'undefined';

export const isBrowser = typeof window !== 'undefined';

export function getParamNames(func) {
	const fnStr = func.toString().replace(STRIP_COMMENTS, '');
	let result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')')).match(ARGUMENT_NAMES);
	if (result === null)
		result = [];
	return result;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
export function template(strings, ...keys) {
	return (function (...values) {
		let dict = values[values.length - 1] || {};
		let result = [strings[0]];
		let path = '';

		keys.forEach(function (key, i) {
			let value = Number.isInteger(key) ? values[key] : dict[key];
			result.push(value, strings[i + 1]);
		});

		for (const string of result) {
			if (!string) break;
			path += string;
		}
		return path;
	});
}

export function getRandom(min = 1, max = 9999999999, fractionDigits = 2) {
	return Number((Math.random() * (max - min) + min).toFixed(fractionDigits));
}

export function scrollInto(ref, timeout = 0, callback) {
	setTimeout(() => {
		ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
		callback && callback();
	}, timeout);
}

export function getLocaleDate() {
	const language = getStorageLanguage();
	switch(language) {
		case 'pl': return pl;
		default: return enGB;
	}
}